<template>
    <div class="phContentThree">
        <div class="phTBox">
            <!-- 左边文字 -->
            <div class="phTLeftText wow animate__animated animate__slideInDown">
                <div class="phTbig">专利技术为网站全程护航</div>
                <div class="phTList">
                    <!-- 左边li -->
                    <div class="phLleft">
                        <ul>
                            <li class="phLli">
                                <div class="plIcon">
                                    <img src="http://iv.okvu.cn/vugw/img/vu818.png" alt="" />
                                </div>
                                <p class="plp">网页优化加速，节省更多流量</p>
                            </li>
                            <li class="phLli">
                                <div class="plIcon">
                                    <img src="http://iv.okvu.cn/vugw/img/vu818.png" alt="" />
                                </div>
                                <p class="plp">前沿的HTML5技术</p>
                            </li>
                            <li class="phLli">
                                <div class="plIcon">
                                    <img src="http://iv.okvu.cn/vugw/img/vu818.png" alt="" />
                                </div>
                                <p class="plp">拇指化TOUCH设计，良好的界面体验</p>
                            </li>
                            <li class="phLli">
                                <div class="plIcon">
                                    <img src="http://iv.okvu.cn/vugw/img/vu818.png" alt="" />
                                </div>
                                <p class="plp">与PC站点数据无缝互联</p>
                            </li>
                            <li class="phLli">
                                <div class="plIcon">
                                    <img src="http://iv.okvu.cn/vugw/img/vu818.png" alt="" />
                                </div>
                                <p class="plp">B/S多层架构，安全高效</p>
                            </li>
                            <li class="phLli">
                                <div class="plIcon">
                                    <img src="http://iv.okvu.cn/vugw/img/vu818.png" alt="" />
                                </div>
                                <p class="plp">快速开发</p>
                            </li>
                        </ul>
                    </div>
                    <!-- 右边li -->
                    <div class="phLright">
                        <ul>
                            <li class="phRli">
                                <div class="prIcon">
                                    <img src="http://iv.okvu.cn/vugw/img/vu818.png" alt="" />
                                </div>
                                <p class="prp">灵活定制</p>
                            </li>
                            <li class="phRli">
                                <div class="prIcon">
                                    <img src="http://iv.okvu.cn/vugw/img/vu818.png" alt="" />
                                </div>
                                <p class="prp">搞扩展性</p>
                            </li>
                            <li class="phRli">
                                <div class="prIcon">
                                    <img src="http://iv.okvu.cn/vugw/img/vu818.png" alt="" />
                                </div>
                                <p class="prp">智能路由</p>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <!-- 右边图片 -->
            <div class="phTRightImg">
                <img src="http://iv.okvu.cn/vugw/img/tp31.jpg" alt="" class="phTimg wow animate__animated animate__slideInUp" />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "PhContentThree",
    data() {
        return {};
    },
    mounted() {
        this.$AOS.init({
            offset: 200,
            duration: 600,
            easing: "ease-in-sine",
            delay: 100,
        });
        new this.$wow.WOW().init({
            boxClass: "wow",
            animateClass: "animate__animated",
            offset: 0,
            mobile: true,
            live: true,
        });
    },
};
</script>

<style lang="less" scoped>
.phContentThree {
    width: 100%;
    height: 670px;
    margin-top: 150px;
    background-color: white;
    .phTBox {
        width: 1280px;
        height: 100%;
        margin: 0 auto;
        // 左边文字
        .phTLeftText {
            width: 700px;
            height: 100%;
            float: left;
            .phTbig {
                width: 100%;
                height: 50px;
                line-height: 50px;
                text-align: center;
                font-size: 36px;
                font-weight: bold;
                color: #333;
                font-family: "Microsoft YaHei Regular", "Microsoft YaHei", Arial, Helvetica, sans-serif;
                padding-top: 200px;
                box-sizing: content-box;
            }
            .phTList {
                width: 100%;
                height: 400px;
                margin-top: 20px;
                .phLleft {
                    width: 70%;
                    height: 100%;
                    float: left;
                    display: flex;
                    justify-content: space-between;
                    flex-direction: column;
                    .phLli {
                        width: 100%;
                        height: 50px;
                        line-height: 50px;
                        font-size: 22px;
                        margin-top: 10px;
                        .plIcon {
                            width: 30px;
                            height: 50px;
                            float: left;
                            position: relative;
                            overflow: hidden;
                            img {
                                position: absolute;
                                top: -88px;
                                right: -520px;
                            }
                        }
                        .plp {
                            float: left;
                        }
                    }
                }
                .phLright {
                    width: 30%;
                    height: 100%;
                    float: left;
                    display: flex;
                    justify-content: space-between;
                    flex-direction: column;
                    .phRli {
                        width: 100%;
                        height: 50px;
                        line-height: 50px;
                        font-size: 22px;
                        margin-top: 10px;
                        .prIcon {
                            width: 30px;
                            height: 50px;
                            float: left;
                            position: relative;
                            overflow: hidden;
                            img {
                                position: absolute;
                                top: -88px;
                                right: -520px;
                            }
                        }
                        .prp {
                            float: left;
                        }
                    }
                }
            }
        }
        // 右边图片
        .phTRightImg {
            width: 40%;
            height: 80%;
            margin-top: 100px;
            margin-right: 50px;
            float: right;
            .phTimg {
                width: 100%;
                height: 100%;
            }
        }
    }
}
</style>
