<template>
    <div class="thePhone">
        <!-- 内容一 -->
        <PhContentOne></PhContentOne>
        <!-- 内容二 -->
        <PhContentTwo :phoneData="phoneData"></PhContentTwo>
        <!-- 内容三 -->
        <PhContentThree></PhContentThree>
        <!-- 内容四 -->
        <PhContentFour :phoneData="phoneData"></PhContentFour>
        <!-- 内容五 -->
        <PhContentFive :phoneData="phoneData"></PhContentFive>
    </div>
</template>

<script>
import PhContentOne from "./PhContent/PhContentOne.vue";
import PhContentTwo from "./PhContent/PhContentTwo.vue";
import PhContentThree from "./PhContent/PhContentThree.vue";
import PhContentFour from "./PhContent/PhContentFour.vue";
import PhContentFive from "./PhContent/PhContentFive.vue";

export default {
    name: "ThePhone",
    data() {
        return {
            phoneData: {},
        };
    },
    components: {
        PhContentOne,
        PhContentTwo,
        PhContentThree,
        PhContentFour,
        PhContentFive,
    },
    async created() {
        let { data } = await axios.get("/api/project/mobilephone");
        this.phoneData = data;
    },
};
</script>

<style lang="less" scoped>
.thePhone {
    min-width: 1423px;
    margin: 0 auto;
    // margin-top: 87px;
    min-width: 1423px;
}
</style>
