<template>
    <div class="PhContentOne">
        <div class="phoBox">
            <!-- 左边文字 -->
            <div class="phoLeftText">
                <div class="phoBig">您需要一个好的手机网站</div>
                <div class="phoSmall">
                    <span>合格的手机网站</span>必须兼容各类型手机（不同厂家、不同尺寸、不同系统）、
                    各种网速环境都能流畅访问，<span>好的手机网站</span>，还可以平滑拓展到微站、APP客户端、小程序运用中。
                </div>
            </div>
            <!-- 右边小文字 -->
            <div class="phoRightText">
                <ul>
                    <li class="phoLi wow animate__animated animate__slideInUp">
                        <div class="pl1">
                            <p>1</p>
                        </div>
                        <div class="pl2">导航复杂</div>
                        <img src="http://iv.okvu.cn/vugw/img/thjiao.png" alt="" class="pl3" />
                        <div class="plx"></div>
                    </li>
                    <li class="phoLi wow animate__animated animate__slideInUp">
                        <div class="pl1">
                            <p>2</p>
                        </div>
                        <div class="pl2">字体太小</div>
                        <img src="http://iv.okvu.cn/vugw/img/thjiao.png" alt="" class="pl3" />
                        <div class="plx2"></div>
                    </li>
                    <li class="phoLi wow animate__animated animate__slideInUp">
                        <div class="pl1">
                            <p>3</p>
                        </div>
                        <div class="pl2">设计平庸</div>
                        <img src="http://iv.okvu.cn/vugw/img/thjiao.png" alt="" class="pl3" />
                        <div class="plx3"></div>
                    </li>
                    <li class="phoLi wow animate__animated animate__slideInUp">
                        <div class="pl1">
                            <p>4</p>
                        </div>
                        <div class="pl2">用户体验不好</div>
                        <img src="http://iv.okvu.cn/vugw/img/thjiao.png" alt="" class="pl3" />
                        <div class="plx"></div>
                    </li>
                    <li class="phoLis">
                        <img
                            class="phoImg wow animate__animated animate__bounceInUp"
                            src="http://iv.okvu.cn/vugw/img/tp11.png"
                            alt=""
                        />
                        <img
                            src="http://iv.okvu.cn/vugw/img/tp12.png"
                            alt=""
                            class="phoImg2 wow animate__animated animate__bounceInUp"
                            data-wow-delay="0.5s"
                        />
                        <img
                            src="http://iv.okvu.cn/vugw/img/tp13.png"
                            alt=""
                            class="phoImg3 wow animate__animated animate__bounceInUp"
                            data-wow-delay="0.1s"
                        />
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "PhContentOne",
    data() {
        return {};
    },
    mounted() {
        this.$AOS.init({
            offset: 200,
            duration: 600,
            easing: "ease-in-sine",
            delay: 100,
        });
        new this.$wow.WOW().init({
            boxClass: "wow",
            animateClass: "animate__animated",
            offset: 0,
            mobile: true,
            live: true,
        });
    },
};
</script>

<style lang="less" scoped>
.PhContentOne {
    width: 100%;
    height: 780px;
    // margin-top: 87px;
    // background: url("vu818.com/uploadfiles/images/%E5%BE%AE%E4%BF%A1%E8%BF%90%E7%94%A8/%E6%89%8B%E6%9C%BA%E7%BD%91%E7%AB%99/bg.jpg");
    .phoBox {
        width: 1280px;
        height: 100%;
        margin: 0 auto;
        position: relative;
        // 左边文字
        .phoLeftText {
            width: 40%;
            height: 250px;
            padding-top: 240px;
            float: left;
            .phoBig {
                width: 100%;
                height: 47px;
                line-height: 47px;
                font-size: 36px;
                font-weight: bold;
                text-align: center;
            }
            .phoSmall {
                width: 88%;
                height: 144px;
                font-size: 20px;
                line-height: 36px;
                margin: 0 auto;
                margin-top: 40px;
                // font-size: 25px;
                text-align: left;
                span {
                    color: #ff6c00;
                }
            }
        }
        // 右边小文字
        .phoRightText {
            width: 657px;
            height: 100%;
            float: right;
            margin-right: 32px;
            margin-top: 108px;
            ul {
                width: 100%;
                height: 100%;
                position: relative;
                .phoLi {
                    width: 150px;
                    height: 100px;
                    z-index: 99;
                    .pl1 {
                        width: 40px;
                        height: 40px;
                        line-height: 40px;
                        text-align: center;
                        border-radius: 50%;
                        border: 10px solid #495271;
                        background-color: #ffa800;
                        position: absolute;
                        left: -10%;
                        top: -10%;
                        box-sizing: content-box;
                        p {
                            color: white;
                            font-size: 24px;
                            font-weight: 900;
                        }
                    }
                    .pl2 {
                        width: 150px;
                        height: 40px;
                        text-align: center;
                        line-height: 40px;
                        padding-left: 45px;
                        color: white;
                        font-weight: 900;
                        font-size: 17px;
                        box-sizing: border-box;
                        background-color: #495271;
                    }
                    .pl3 {
                        position: absolute;
                        right: 0%;
                        top: 40%;
                    }
                    .plx,
                    .plx2,
                    .plx3 {
                        position: absolute;
                        top: 55px;
                        right: 0px;
                        width: 1px;
                        height: 80px;
                        display: block;
                        background: #495271;
                        opacity: 0.3;
                        content: "";
                        float: right;
                        margin-right: 15px;
                    }
                    .plx2 {
                        height: 200px;
                    }
                    .plx3 {
                        height: 265px;
                    }
                }
                .phoLis {
                    .phoImg {
                        position: absolute;
                        top: 100px;
                        left: 180px;
                        z-index: 88;
                    }
                    .phoImg2 {
                        position: absolute;
                        top: 180px;
                        left: 30px;
                        z-index: 77;
                    }
                    .phoImg3 {
                        position: absolute;
                        top: 250px;
                        left: 20px;
                        z-index: 0;
                    }
                }
                .phoLi:nth-child(1) {
                    position: absolute;
                    top: 150px;
                    left: 50px;
                }
                .phoLi:nth-child(2) {
                    position: absolute;
                    top: 55px;
                    left: 320px;
                }
                .phoLi:nth-child(3) {
                    position: absolute;
                    top: 160px;
                    right: 20px;
                }
                .phoLi:nth-child(4) {
                    position: absolute;
                    bottom: 250px;
                    right: 250px;
                }
            }
        }
    }
}
</style>
