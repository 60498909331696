<template>
    <div class="phContentFive">
        <div class="phFBox">
            <!-- 顶部标题 -->
            <div class="phFTopTitle">
                <span>手机网站精品案例</span>
                <span @click="goCase">&nbsp;查看全部案例></span>
            </div>
            <!-- 手机列表 -->
            <div class="phFList">
                <ul>
                    <li v-for="(el, i) in list" :key="i">
                        <vy-phone
                            :element="el"
                            @callBack="$router.push({ name: 'listDetail', params: { id: el.id } })"
                        ></vy-phone>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import VyPhone from "@/util/VyPhone.vue";

export default {
    components: { VyPhone },
    name: "PhContentFive",
    props: ["phoneData"],
    data() {
        return {
            list: [],
            first_id: "",
            second_id: "",
        };
    },
    async created() {},
    methods: {
        goCase() {
            this.$router.push(`/case?id=${this.second_id}&fuid=${this.first_id}`);
        },
    },
    watch: {
        async phoneData(newVal) {
            let nums = [];
            let { data, first_id, second_id } = this.phoneData;
            if (data.length > 20) data = data.slice(0, 20);
            if (data.length < 8) {
                //  未满8
                nums = data;
            } else {
                for (let i = 0; i < 8; i++) {
                    nums.push(sj(data.length));
                }
                nums.forEach((e, i) => {
                    nums[i] = data[e];
                });
            }
            let { data: list } = await axios.get("/api/project/mobilephoneid", { params: { id: nums.join(",") } });
            this.list = list;
            this.first_id = first_id;
            this.second_id = second_id;
            function sj(range) {
                // 0 ~ range随机数
                let num = Math.floor(Math.random() * range);
                if (nums.findIndex(e => e == num) != -1) return sj(range);
                return num;
            }
        },
    },
};
</script>

<style lang="less" scoped>
.phContentFive {
    width: 100%;
    // height: 1170px;
    padding-bottom: 50px;
    // margin-top: 50px;
    .phFBox {
        width: 1280px;
        height: 100%;
        margin: 0 auto;
        overflow: hidden;
        // 顶部标题
        .phFTopTitle {
            // width: 30%;
            height: 50px;
            line-height: 50px;
            text-align: center;

            margin: 0 auto;
            margin-top: 50px;
            span:nth-of-type(1) {
                font-size: 36px;
                font-weight: 900;
            }
            span:nth-of-type(2) {
                color: #7781f1;
                cursor: pointer;
            }
        }
        // 手机列表
        .phFList {
            width: 100%;
            // height: 1030px;
            ul {
                width: 100%;
                // height: 100%;
                display: flex;
                flex-wrap: wrap;
                justify-content: flex-start;
                align-content: flex-start;
                li {
                    width: 25%;
                    margin-top: 50px;
                    & > div {
                        margin: 0 auto;
                    }
                }
            }
        }
    }
}
</style>
